import axios from '../axios'

const  EmployeesService = (function(){
    
    let instance;
    function Service(){}
    Service.prototype.findAllContacts = function(){
       
        return axios.get(`contacts`).then((res) => {
            return res.data;
        }).catch((err) => {
            return err;
        })
    }

    Service.prototype.saveCustomerContactInformation = function(contactInformation) {

        return axios.post(`contacts`, contactInformation).then(res => {
            return res.data
        }).catch((err) => {
            return err
        })

    }

    return {
        getInstance: () => {
            if(!instance)
            {
                instance = new Service();
            }

            return instance;
        }    
    }
    
})()

export default EmployeesService
