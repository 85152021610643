<template>
  <div class="global-blue">
    <v-container>
      <v-row justify="center">
        <v-dialog v-model="contact" persistent max-width="650px">
          <v-card class="contact" dark>
            <v-card-title
              aria-multiline
            >{{ locale == 'fi' ? 'Jätä yhteydenottopyyntö' : 'Leave us your contact information'}}</v-card-title>
            <template v-if="locale == 'fi'">
              <p class="mx-6" @click="closeform()">
                Tai katso
                <router-link :to="{name: 'contacts'}">yhteystiedot</router-link>
              </p>
            </template>
            <template v-else>
              <p class="mx-6" @click="closeform()">
                Or checkout
                <router-link :to="{name: 'contacts'}">contacts</router-link>
              </p>
            </template>
            <ValidationObserver v-slot="{ invalid }">
              <ValidationProvider rules="alpha_spaces|required" name="Name">
                <v-text-field
                  class="mx-4 my-n2"
                  filled
                  slot-scope="{
                              errors,
                              valid
                            }"
                  v-model="name"
                  :error-messages="errors"
                  :success="valid"
                  :label="locale == 'fi' ? 'Nimi' : 'Name'"
                  placeholder="Eino Esimerkki"
                  required
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider rules="required" name="Organization">
                <v-text-field
                  class="mx-4 my-n2"
                  filled
                  slot-scope="{
                              errors,
                              valid
                            }"
                  v-model="organization"
                  :error-messages="errors"
                  :success="valid"
                  :label="locale == 'fi' ? 'Organisaatio' : 'Organization'"
                  placeholder="Esimerkki Oy"
                  required
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider rules="email|required" name="Name">
                <v-text-field
                  class="mx-4 my-n2"
                  filled
                  slot-scope="{
                              errors,
                              valid
                            }"
                  v-model="email"
                  :error-messages="errors"
                  :success="valid"
                  :label="locale == 'fi' ? 'Sähköposti' : 'Email'"
                  placeholder="eino.esimerkki@esimerkki.fi"
                  required
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                :rules="{ regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, required: true}"
                name="Phone"
              >
                <v-text-field
                  class="mx-4 my-n2"
                  filled
                  slot-scope="{
                              errors,
                              valid
                            }"
                  v-model="phone"
                  :error-messages="errors"
                  :success="valid"
                  :label="locale == 'fi' ? 'Puhelin' : 'Phone'"
                  placeholder="+358 12 345 567"
                  required
                ></v-text-field>
              </ValidationProvider>

              <v-textarea
                v-model="info"
                class="mx-4 my-n2"
                filled
                :label="locale == 'fi' ? 'Lisätietoja' : 'Additional information'"
              ></v-textarea>

              <ValidationProvider :rules="{ required: { allowFalse: false }} " name="gdpr">
                <v-checkbox
                  class="mx-4 my-n2"
                  slot-scope="{ errors, valid }"
                  v-model="gdpr"
                  :error-messages="errors"
                  :success="valid"
                  :label="text"
                ></v-checkbox>
              </ValidationProvider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error darken-1"
                  text
                  @click="closeform()"
                >{{ locale == 'fi' ? 'Peruuta' : 'Cancel' }}</v-btn>
                <v-btn
                  :disabled="invalid"
                  :color="$orange"
                  text
                  @click="closeform('sent')"
                >{{ locale == 'fi' ? 'Lähetä' : 'Send' }}</v-btn>
              </v-card-actions>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.contact {
  background: #002135;
}
</style>

<script>
import contactsService from "../services/contacts";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import {
  min,
  max,
  regex,
  required,
  email,
  alpha_spaces
} from "vee-validate/dist/rules";

export default {
  name: "ContactForm",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      contact: true,
      isLoading: true,
      name: "",
      organization: "",
      email: "",
      phone: "",
      info: "",
      gdpr: false,
      active: false
    };
  },
  props: {
    show: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    closeform(status) {
      if (!status) return this.$emit("closed");

      if (this.name && this.organization && this.email && this.phone) {
        const payload = {
          name: this.name,
          organization: this.organization,
          email: this.email,
          phone: this.phone,
          info: this.info
        };

        return this.postContactInformation(payload).then(() => {
            this.$emit("closed", undefined, true);
        })
        
      }

      // console.log("Tried to sent incomplete form, not allowed");
    },
    postContactInformation(data) {
      return contactsService
        .getInstance()
        .saveCustomerContactInformation(data)
        .then(response => {
          // console.log("res ", response);
          if(response.toString().includes('Error')) {
            // console.log("error", response)
            return this.$emit("closed", undefined, true);
              
          }
          return this.$emit("closed", true);
        })
        .catch(() => {
          // console.log(err);
          return this.$emit("closed", undefined, true);
        });
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    text() {
      return this.locale == "fi"
        ? "Sallin tietojeni käsittelyn ja säilyttämisen yhteydenottamista varten"
        : "I allow my information to be used and retained to contact me";
    }
  },
  created() {
    extend("max", {
      ...max,
      message:
        this.locale == "fi"
          ? "Viestin maksimipituus on 500 merkkiä"
          : "Message can't be longer than 500 characters"
    });

    extend("min", {
      ...min,
      message: ""
    });

    extend("required", {
      ...required,
      message:
        this.locale == "fi"
          ? "Tämä tieto vaaditaan pyynnön välittämiseksi"
          : "This field is required"
    });

    extend("alpha_spaces", {
      ...alpha_spaces,
      message:
        this.locale == "fi"
          ? "Numerot eivät ole sallittuja tässä kentässä"
          : "Please remove numeric values from this field"
    });

    extend("email", {
      ...email,
      message:
        this.locale == "fi"
          ? 'Sähköpostiosoitteen tulee olla muotoa "esimerkki@esimerkki.fi"'
          : "Valid email is required"
    });

    extend("regex", {
      ...regex,
      message:
        this.locale == "fi" ? "Tarkista numero" : "Phone number must be valid"
    });
  }
};
</script>
