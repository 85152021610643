var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-blue"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"650px"},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}},[_c('v-card',{staticClass:"contact",attrs:{"dark":""}},[_c('v-card-title',{attrs:{"aria-multiline":""}},[_vm._v(_vm._s(_vm.locale == 'fi' ? 'Jätä yhteydenottopyyntö' : 'Leave us your contact information'))]),(_vm.locale == 'fi')?[_c('p',{staticClass:"mx-6",on:{"click":function($event){return _vm.closeform()}}},[_vm._v(" Tai katso "),_c('router-link',{attrs:{"to":{name: 'contacts'}}},[_vm._v("yhteystiedot")])],1)]:[_c('p',{staticClass:"mx-6",on:{"click":function($event){return _vm.closeform()}}},[_vm._v(" Or checkout "),_c('router-link',{attrs:{"to":{name: 'contacts'}}},[_vm._v("contacts")])],1)],_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var valid = ref.valid;
return _c('v-text-field',{staticClass:"mx-4 my-n2",attrs:{"filled":"","error-messages":errors,"success":valid,"label":_vm.locale == 'fi' ? 'Nimi' : 'Name',"placeholder":"Eino Esimerkki","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Organization"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var valid = ref.valid;
return _c('v-text-field',{staticClass:"mx-4 my-n2",attrs:{"filled":"","error-messages":errors,"success":valid,"label":_vm.locale == 'fi' ? 'Organisaatio' : 'Organization',"placeholder":"Esimerkki Oy","required":""},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"email|required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var valid = ref.valid;
return _c('v-text-field',{staticClass:"mx-4 my-n2",attrs:{"filled":"","error-messages":errors,"success":valid,"label":_vm.locale == 'fi' ? 'Sähköposti' : 'Email',"placeholder":"eino.esimerkki@esimerkki.fi","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, required: true},"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var valid = ref.valid;
return _c('v-text-field',{staticClass:"mx-4 my-n2",attrs:{"filled":"","error-messages":errors,"success":valid,"label":_vm.locale == 'fi' ? 'Puhelin' : 'Phone',"placeholder":"+358 12 345 567","required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})}}],null,true)}),_c('v-textarea',{staticClass:"mx-4 my-n2",attrs:{"filled":"","label":_vm.locale == 'fi' ? 'Lisätietoja' : 'Additional information'},model:{value:(_vm.info),callback:function ($$v) {_vm.info=$$v},expression:"info"}}),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false }},"name":"gdpr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var valid = ref.valid;
return _c('v-checkbox',{staticClass:"mx-4 my-n2",attrs:{"error-messages":errors,"success":valid,"label":_vm.text},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}})}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){return _vm.closeform()}}},[_vm._v(_vm._s(_vm.locale == 'fi' ? 'Peruuta' : 'Cancel'))]),_c('v-btn',{attrs:{"disabled":invalid,"color":_vm.$orange,"text":""},on:{"click":function($event){return _vm.closeform('sent')}}},[_vm._v(_vm._s(_vm.locale == 'fi' ? 'Lähetä' : 'Send'))])],1)]}}])})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }